import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../Media/Logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './navbar.css';

const pages = [
    {
        "label": "Home",
        "href": "/",
        "subpages": [
            {
                "label": "About us",
                "href": "/about-us"
            },
            {
                "label": "Board of Trustees",
                "href": "/board-of-trustees"
            },
        ]
    },
    {
        "label": "Domains",
        "subpages": [
            {
                "label": "Geoinformatics",
                "href": "/geoinformatics"
            },
            {
                "label": "CSR",
                "href": "/csr"
            },
            {
                "label": "Business Incubation",
                "href": "/business-incubation"
            },
            {
                "label": "Skill Training",
                "href": "/skill-training",
                "subpages": [
                    {
                        "label": "BARTI",
                        "href": "/skill-training/barti"
                    },
                    {
                        "label": "EU DST NET Skills",
                        "href": "/skill-training/eu-dst-net-skills"
                    },
                    {
                        "label": "Technology Based Skill Training Projects",
                        "href": "/skill-training/technology-based-skill-training-projects"
                    },
                    {
                        "label": "Skill Training for Differently Abled Persons",
                        "href": "/skill-training/skill-training-for-differently-abled-persons"
                    },
                ]
            },
            {
                "label": "Sustainability",
                "href": "https://sustainability.scitechpark.org.in/home"
            },
            {
                "label": "Environment",
                "href": "/environment"
            },
            {
                "label": "Integrated Village Development",
                "href": "/integrated-village-development",
                "subpages": [
                    {
                        "label": "Panawadi",
                        "href": "/integrated-village-development/panawadi"
                    },
                    {
                        "label": "Chandar",
                        "href": "/integrated-village-development/chandar"
                    },
                ],
            },
        ]
    },
    {
        "label": "Business Incubation",
        "subpages": [
            {
                "label": "Startup India Seed Fund Scheme",
                "href": "/startup-india-seed-fund-scheme"
            },
            {
                "label": "Growth Lab",
                "href": "/business-incubation/growth-lab"
            },
            {
                "label": "Our Incubatees",
                "href": "/incubatees"
            },
            {
                "label": "Products Innovation",
                "href": "",
                "subpages": [
                    {
                        "label": "Scitech UV SAN",
                        "href": "/products-innovation/scitech-uv-san",
                    },
                    {
                        "label": "Scitech AIROSAN",
                        "href": "/products-innovation/scitech-airosan",
                        "subpages": [
                            {
                                "label": "Plug-in",
                                "href": "/products-innovation/scitech-airosan/plug-in",
                            },
                            {
                                "label": "Traveller",
                                "href": "/products-innovation/scitech-airosan/traveller",
                            },
                        ]
                    },
                    {
                        "label": "Scitech HIVENION",
                        "href": "/products-innovation/scitech-hivenion",
                    }
                ]
            },
            {
                "label": "NIDHI EIR",
                "href": "/nidhi-eir"
            },
            {
                "label": "NIDHI SSS",
                "href": "/nidhi-sss"
            },
            {
                "label": "NIDHI PRAYAS",
                "href": "/nidhi-prayas",
                "subpages": [
                    {
                        "label": "Equipments",
                        "href": `${process.env.REACT_APP_STP_URL
                            }/equipmentsPdf/DST+Prayas+Shala+-+List+of+Equipments.pdf`,
                        "target": "_blank",
                    }
                ]
            },
            {
                "label":"Nidhi Accelerator",
                "href": "/nidhi-accelerator",
                "subpages": [
                    {
                        "label": "Scitech Climate Runway",
                        "href": "/climate-runway",
                    },
                    {
                        "label": "Scitech Stepup MANXL",
                        "href": "/stepup-manxl",
                    },
                ]

            },
            {
                "label": "CIII Centers",
                "href": "/ciii-centers"
            },
            {
                "label": "Awards and Achievement",
                "href": "/awards"
            }
        ]
    },
    {
        "label": "Events",
        "href": "/events"
    },
    {
        "label": "News & Media",
        "href": "/news-and-media"
    },
    {
        "label": "Donation",
        "subpages": [
            {
                "label": "STP Donations",
                "href": "/stp-donations"
            },
            {
                "label": "Green Donations",
                "href": "/green-donations"
            }
        ]
    },
    {
        "label": "Contact Us →",
        "href": "/contact-us"
    }
];


const Navbar = () => {
    const location = useLocation();
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const navbarRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                navbarRef.current &&
                !navbarRef.current.contains(event.target) &&
                !event.target.classList.contains("navbar-toggler")
            ) {
                setOpenDropdowns({});
                setIsNavbarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const isActive = (page) => {

        const checkSubpages = (subpages) => {
            return subpages?.some((subpage) =>
                subpage.href === location.pathname || checkSubpages(subpage.subpages)
            );
        };

        return (
            page.href === location.pathname || checkSubpages(page.subpages)
        );
    };

    const handleDropdownClick = (event, href) => {
        if (href && (href.startsWith('http') || href.startsWith('www'))) {
            window.location.href = href;
        } else if (href) {
            event.preventDefault();
            navigate(href);
        }
    };

    const toggleNavbar = (event) => {
        setIsNavbarOpen((prev) => !prev); // Toggle navbar open/close
    };



    const toggleDropdown = (index, subIndex = null, nestedIndex = null) => {
        setOpenDropdowns(prevState => {
            const newState = { ...prevState };

            if (nestedIndex !== null) {
                newState[index] = {
                    ...newState[index],
                    [subIndex]: {
                        ...newState[index]?.[subIndex],
                        [nestedIndex]: !newState[index]?.[subIndex]?.[nestedIndex],
                    },
                };
            } else if (subIndex !== null) {
                newState[index] = {
                    ...newState[index],
                    [subIndex]: !newState[index]?.[subIndex],
                };
            } else {
                newState[index] = !newState[index];
            }

            return newState;
        });
    };

    const renderSubmenu = (page, index, subIndex = null, nestedIndex = null) => {
        if (!page.subpages) return null;
        return (
            <ul className={`dropdown-menu ${openDropdowns[index]?.[subIndex]?.[nestedIndex] ? "show" : ""}`} aria-labelledby={`navbarDropdown-${index}`}>
                {page.subpages.map((subpage, subIndex) => (
                    <li key={`${index}-${subIndex}`} className={`${subpage.subpages ? "dropdown-submenu" : ""}`}>
                        {subpage.subpages ? (
                            <>
                                <a
                                    href={subpage.href || "#"}
                                    className={`dropdown-item dropdown-toggle ${isActive(subpage.href) ? "active" : ""}`}
                                    onClick={(e) => handleDropdownClick(e, subpage.href)}
                                    data-bs-toggle="dropdown"
                                    aria-expanded={openDropdowns[index]?.[subIndex] ? "true" : "false"}
                                >
                                    {subpage.label}
                                </a>
                                {renderSubmenu(subpage, index, subIndex)}
                            </>
                        ) : (
                            <Link
                                to={subpage.href || "#"}
                                className={`dropdown-item ${isActive(subpage.href) ? "active" : ""}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDropdownClick(e, subpage.href);
                                    setIsNavbarOpen(false);
                                }}
                            >
                                {subpage.label}
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <nav ref={navbarRef} className="navbar navbar-expand-lg navbar-light bg-white shadow-sm sticky-top">
            <div className="container">
                <a className="navbar-brand d-flex align-items-center logo-img" href="/" style={{ cursor: "pointer" }}>
                    <img src={Logo} alt="Logo" style={{ width: "450px" }} />
                </a>
                <button
                    className={`navbar-toggler ${isNavbarOpen ? "" : "collapsed"}`}
                    type="button"
                    aria-expanded={isNavbarOpen ? "true" : "false"}
                    onClick={toggleNavbar}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`} id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto">
                        {pages.map((page, index) => (
                            <li
                                key={index}
                                className={`nav-item ${page.subpages ? "dropdown" : ""} ${isActive(page) ? "active" : ""
                                    }`}
                                onMouseEnter={() => toggleDropdown(index)}
                                onMouseLeave={() => toggleDropdown(index)}
                            >
                                {page.subpages ? (
                                    <>
                                        <Link
                                            to={page.href || "#"}
                                            className="nav-link dropdown-toggle"
                                            id={`navbarDropdown-${index}`}
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded={false}
                                            onClick={(e) => {
                                                if (page.href) {
                                                    handleDropdownClick(e, page.href);
                                                } else {
                                                    e.preventDefault();
                                                    toggleDropdown(index);
                                                }
                                            }}
                                        >
                                            {page.label}
                                        </Link>
                                        {renderSubmenu(page, index)}
                                    </>
                                ) : (
                                    <Link
                                        to={page.href}
                                        className={`nav-link ${isActive(page.href) ? "active" : ""}`}
                                        onClick={() => setIsNavbarOpen(false)}
                                    >
                                        {page.label}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;