import React, { useState } from "react";
import Banner from "./Banner";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NidhiEIRCard from "./NidhiEIRCard";
import EirImage from '../../../Media/Nidhi_EIR.avif';
import eirLogo from '../../../Media/eirLogo.png'
import SmallDataCard from "./smallDataCard";
import YearModal from "./YearModal";

const sectionStyle = {
    py: 4,
    backgroundColor: "#d7e3fc",
    color: "#1C1F35",
};

const sectionStyle2 = {
    py: 4,
    backgroundColor: "#ffffff",
    color: "#1C1F35",
};

const supporters = [
    {
        name: "Jaideep Aher",
        description:
            "Jaideep is developing autonomous robots and robotic kits for agriculture in India with applications like pest and weed detection, fertilizer and pesticide recommendation, weed removal, plant health detection, pesticide and fertilizer spraying, and crop harvesting. Their core innovation is technology for autonomous detection of weeds, fruits, and vegetables, aiming to automate various farming processes for improved efficiency.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/Agrofix+Robotics+Logo-+Jaideep+Aher.png`
    },
    {
        name: "Mayur Gajare",
        description:
            "Mayur is working on an advanced surgical training and assessment platform leveraging virtual reality (VR) to revolutionize medical education by offering a realistic, risk-free training environment. Equipped with a high-resolution VR headset, precision controllers, spatial sensors, and a robust software library, the platform enhances skill acquisition, proficiency, and confidence among medical professionals. It is designed for surgical skills development, aiming to elevate training standards.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/hardclikk_logo-+Mayur+Gajare.jpg`
    },
    {
        name: "Dipesh Walte",
        description:
            "Dipesh is developing a novel networking framework called Brainchain, designed for professional networking using a virtual mind concept. It connects strategic individuals, fosters communication through prompts, and enhances networking for social entrepreneurs, curates resources for teachers, and aids RTI activists in managing and sharing information efficiently. This platform aims to streamline collaboration and innovation across various sectors.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/Brainchain-+Dipesh+Walte_logo.jpg`
    },
    {
        name: "Pratyusha Cheepu",
        description:
            "Pratyusha is developing a pain-free, real-time glucometer that measures blood glucose levels through sweat using a hydrogel-based interface and a sweat glucose sensor with nanostructure hybrid film. The device is affordable, wearable, and connects to an Android app for monitoring and alerts, facilitating self-analysis and better diabetes management, thus reducing complications like heart, kidney, and eye disease.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/Dyonya-+Pratyusha+Cheepu_logo.jpg`
    },
    {
        name: "Dr. Deepak Vikhe",
        description:
            "This project introduces a novel dental implant design that incorporates a vertical reservoir within the screw section to dispense Injectable Platelet Rich Fibrin (I-PRF). This method enhances healing by preventing biofilm formation and promoting tissue integration at the screw/bone interface. The implant also features a polished collar to avoid plaque, a dotted collar for tissue attachment, and a micro-threaded collar to prevent bone loss.",
        // logo: `${process.env.REACT_APP_STP_URL}/`
    },
    {
        name: "Sharad Kadam",
        description:
            "Agroson aims to organize the fragmented agribusiness sector by providing an online platform that connects farmers with various farm service providers. By standardizing services in terms of time, price, and quality, Agroson ensures efficient and reliable farm management solutions. The platform offers end-to-end farming services accessible through a mobile app, benefiting both farmers and service providers through increased customer reach and reduced marketing costs.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/Agroson_alliance_llp_logo.jpg`
    },
    {
        name: "Mohit Kanojia",
        description:
            "OPUS is a mobile robotics operational management system that enhances productivity and efficiency by automating key business processes. It features a centralized project management hub for planning, scheduling, tracking, and generating inspection reports. The integrated PLUME platform offers a modular, scalable hardware solution for mobile robotics, allowing businesses to adjust components as required for optimal performance.",
        // logo: `${process.env.REACT_APP_STP_URL}/`
    },
    {
        name: "Bosco Ruptake",
        description:
            "Versatile Electrolyzer for Green Hydrogen Production features modular cells for easy maintenance, variable stack sizes, and replaceable membranes and electrodes, addressing issues like complex upkeep and scalability. Its applications include lab-scale research, pilot-scale prototyping, hydrogen fueling stations, industrial hydrogen production, and energy storage/grid balancing by utilizing excess renewable energy to produce hydrogen through electrolysis.",
        // logo: `${process.env.REACT_APP_STP_URL}/`
    },
    {
        name: "Shshank Shrivastava",
        description:
            "This project uses technology to create eco-friendly leather from banana waste, addressing environmental and ethical concerns in traditional production.",
        // logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/Bahide-+Shshank+Shrivastva.png`
        logo: eirLogo
    },
    {
        name: "Sarfraj Ansari",
        description:
            "Sarfraj is working on a project ZERO: Development of fuel cells and the creation of a cost-effective alternative to the Nafion membrane. Specific application: Hydrogen Bicycle.",
        // logo: `${process.env.REACT_APP_STP_URL}/`
    },
    {
        name: "Yash Raut",
        description:
            "Yash is working on SHSTRA (Smart Sugarcane Harvesting System Targeting All), developing an ecosystem to centralize operations in sugarcane harvesting and transportation by introducing a Smart Sugarcane Harvester specifically designed for Indian sugarcane farms. It is equipped with unique technology for cane stalk bundling and other allied features, solving problems for most stakeholders.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/EIR/Shstra-+Yash+Raut.jpg`
    },
];


const NidhiEIR = () => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <Banner title="NIDHI EIR" letterSpacing='5px' />
            <Box sx={{
                ...sectionStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: { xs: 2, md: 4 },
                px: { xs: 2, md: 0 },
                backgroundColor: '#ffffff',
            }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    padding: 0,
                }}>
                    <Box sx={{
                        width: { xs: '100%', md: '50%' },
                        paddingRight: { md: 5 },
                        mb: { xs: 2, md: 0 },
                    }}>
                        <Typography
                            variant="body1"
                            fontSize={{ xs: '1rem', md: '1.1rem' }}
                            paragraph
                            sx={{
                                lineHeight: 1.6,
                                textAlign: 'justify',
                                marginBottom: 2,
                            }}>
                            NIDHI-EIR Programme is meant for potential innovative entrepreneurs at selected DST approved Technology Business Incubators. The NIDHI-EIR Programme would provide subsistence grant to an aspiring or budding entrepreneur of considerable potential for pursuing a promising technology business idea.
                        </Typography>
                    </Box>
                    <Box sx={{
                        width: { xs: '100%', md: '50%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <img
                            src={EirImage}
                            alt='EIR'
                            style={{
                                width: '80%',
                                maxWidth: '300px',
                                borderRadius: '5%',
                            }} />
                    </Box>
                </Container>
            </Box>
            <Box sx={sectionStyle}>
                <Container>
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" gutterBottom>
                        The Programme aims:
                    </Typography>
                    <List sx={{ color: "#364356", textAlign: 'justify' }}>
                        <ListItem>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: "1.1rem" }}
                                primary="To encourage graduating students to take to entrepreneurship by providing support as a fellowship." />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: "1.1rem" }}
                                primary="To provide a prestigious forum for deserving and budding entrepreneurs to pursue their ventures without any additional risks involved in technology-based businesses." />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: "1.1rem" }}
                                primary="To create, nurture, and strengthen a pipeline of entrepreneurs for incubators." />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primaryTypographyProps={{ fontSize: "1.1rem" }}
                                primary="To make pursuing entrepreneurship related to a technology business idea more attractive among available career options." />
                        </ListItem>
                    </List>
                </Container>
            </Box>
            <Box sx={sectionStyle2}>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: "#0099DE",
                                fontWeight: 'bold',
                                textAlign: 'center',
                                fontSize: { xs: '1.8rem', md: '2rem' },
                            }}
                        >
                            Financial Support
                        </Typography>

                        <Typography
                            variant="h6"
                            sx={{
                                color: "#364356",
                                fontWeight: 'normal',
                                mt: 1,
                                textAlign: 'center',
                                fontSize: { xs: '1rem', md: '1.2rem' },
                            }}
                        >
                            Monthly grant support in a range of &#8377; 30,000
                        </Typography>

                        <Typography
                            variant="h5"
                            sx={{
                                color: "black",
                                fontWeight: 'bold',
                                mt: 1,
                                textAlign: 'center',
                                fontSize: { xs: '1.4rem', md: '1.6rem' },
                            }}
                        >
                            (Total max. support - &#8377; 3,60,000 per EIR)
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: '#ffffff',
                pb: 2,
                px: { xs: 2, md: 12 },
                width: '100%',
                maxWidth: '900px',
                margin: 'auto',
            }}>
                <Paper
                    sx={{
                        backgroundColor: '#d7e3fc',
                        padding: { xs: '12px', md: '16px' },
                        marginBottom: '16px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                >
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h4" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Eligibility for an NIDHI-EIR support:
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ color: "#5A7184", }}>
                                <List sx={{ textAlign: 'center' }}>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="1. The EIR applicant should be citizen of India. An Indian citizen is defined as one who is in possession of a government approved proof of nationality such as a valid passport, voter’s id etc." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="2. The EIR applicant should have completed Minimum 4 years of formal full time undergraduate/post graduate education towards one or more degree programme. Basic degree or diploma should be in science or engineering. OR  
3 years degree or diploma program and have 2 year full time work experience post degree or diploma. Basic degree or diploma should be in science or engineering."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="3. NIDHI-EIR recipients are expected to be committed to exploring a business idea. Typical NIDHI-EIR needing the support is a first generation innovativeentrepreneur, who has no prior source of income.NIDHI-EIR support recipient should not treat this support as a stop gap arrangement to support them in their academic pursuits or transition between jobs." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="4. NIDHI-EIR seeking the support should demonstrate his ability to build a scalable technology business startup. The background of the NIDHI-EIR needs to be provided in a prescribed format." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="5. The NIDHI-EIRsupport recipient should propose one technology business idea in a prescribed format." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="6. NIDHI-EIR support recipient are expected to pursue their entrepreneurial aspirations full time. NIDHI-EIRcannot be concurrent with any other remuneration or fellowship." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="7. NIDHI-EIR support recipient cannot be the promoter or significant (>10%) share holder / beneficiary of another company at the time of applying for and receiving the grant approval" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="8. The NIDHI-EIRshould be registered for the pre-incubation or incubation program at the TBI for the entire duration of NIDHI-EIR support." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="9. Preference shall be given for a) technology business ideas with larger technology uncertainties and/ or long gestation periods, b) technology business ideas leveraging technology or IP from publicly funded research or academic organizations, c) technology business ideas with considerable potential for social impact." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="10. Entrepreneurs pursuing business ideas with no or marginal technology innovation or those with very shortjourney (i.e less than 6 months) to commercialization are discouraged to apply." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                                            primary="11. NIDHI-EIR seeking support should have a formal business plan for the idea they intend to pursue and a business presentation including the investment proposal." />
                                    </ListItem>
                                </List>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Box>
            <Box sx={sectionStyle}>
                <Container>
                    <Typography
                        color="#152265"
                        variant="h4"
                        fontWeight="600"
                        align="center"
                        py={2}
                        mb={3}
                        gutterBottom
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpen(true)}
                    >
                        Supported Entrepreneurs under NIDHI-EIR (Year 5)
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        {supporters.map((item, index) => (
                            <Grid item xs={12} sm={6} md={5} key={index}>
                                {item.name === "Shshank Shrivastava" || item.name === "Sarfraj Ansari" ? (
                                    <SmallDataCard item={item} />
                                ) : (
                                    <NidhiEIRCard item={item} />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <YearModal open={open} handleClose={() => setOpen(false)} />
        </div>
    );
};

export default NidhiEIR;
