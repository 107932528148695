import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Link,
    Box,
} from '@mui/material';

export default function EventCard({ docUrl, item }) {
    const [showFullText, setShowFullText] = useState(false);

    const toggleText = () => setShowFullText(!showFullText);

    const maxLength = 100;
    const isLongDescription = item.Description.length > maxLength;
    const truncatedDescription = isLongDescription && !showFullText
        ? `${item.Description.slice(0, maxLength)}...`
        : item.Description;

    return (
        <Card
            sx={{
                width: { xs: 350, md: 385 },
                p: 3,
                boxShadow: 0,
                borderRadius: 4,
                backgroundColor: '#fafafa',
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardMedia
                component="img"
                height="190"
                image={`${docUrl}${item?.Images?.[0]}?w=164&h=164&fit=crop&auto=format`}
                alt={item.Title}
                sx={{ borderRadius: "1.3rem" }}
            />
            <CardContent sx={{ 
                p: 0, 
                pt: 3, 
                display: "flex", 
                flexDirection: "column", 
                flexGrow: 1
            }}>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {new Date(item.Start_date).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {item.Title}
                </Typography>

                {/* Description Section - Takes up remaining space */}
                <Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>
                    {truncatedDescription}
                    {isLongDescription && (
                        <Link
                            component="button"
                            variant="body2"
                            sx={{ ml: 1, cursor: 'pointer', color: '#0275d8' }}
                            onClick={toggleText}
                        >
                            {showFullText ? "View Less" : "View More"}
                        </Link>
                    )}
                </Typography>

                {/* Stick "Read More" at the bottom */}
                <Box sx={{ pt: 1, mt: "auto", textAlign: "left" }}>
                    <Link href={`event/${item.Slug}`} target="_self" sx={{ display: 'block', color: '#0275d8' }}>
                        Read More
                    </Link>
                </Box>
            </CardContent>
        </Card>
    );
}
