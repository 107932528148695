import React from "react";
import { Box, Typography } from "@mui/material";

export default function TitleSection() {
  return (
    <Box>
      {/* <Typography
        variant="h6"
        textAlign="center"
        mb={1.5}
        sx={{ color: "#008bf9" }}
      >
        Our Focus Area
      </Typography> */}
      <Typography
        variant="h4"
        textAlign="center"
        mb={4}
        sx={{ fontWeight: "bold", color: "#1c2b78" }}
      >
        Business Incubation
      </Typography>
    </Box>
  );
}
