import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
} from "@mui/material";
import Banner from "../../Components/Banner";
import axios from "axios";

export default function SpecialProjectsPage() {
    const [specialProjects, setSpecialProjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchSpecialProjects = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/specialproject/getall"
                );
                const allData = response?.data?.result;
                console.log(allData);
                if (Array.isArray(allData)) {
                    const latestData = allData.reverse();
                    setSpecialProjects(latestData);
                } else {
                    throw new Error("Invalid response data");
                }
            } catch (error) {
                console.error(error);
                setError("Failed to fetch special projects. Please try again later.");
            } finally {
                setLoading(false);
            }
        };

        fetchSpecialProjects();
    }, []);

    return (
        <>
            <Banner title={'Special Projects'} />
            <Box
                sx={{
                    backgroundColor: "#f9fafc",
                    py: { xs: 4, md: 6 },
                    px: { xs: 2, md: 4 },
                    color: "#1c2b78",
                }}
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        flexWrap: "wrap",
                        p: { xs: 1, sm: "2rem", md: "2rem 4rem", lg: "2rem 6rem" },
                        gap: { xs: 2, sm: 2, md: 4, lg: 0 }
                    }}
                >
                    {specialProjects.map((project) => (
                        <Grid item xs={12} sm={8} md={5} lg={3} key={project.Project_id}>
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    minWidth: 240,
                                    maxWidth: "100%",
                                    backgroundColor: "#fff",
                                    borderRadius: "10px",
                                    color: "#000",
                                    height: "100%",
                                    boxShadow: 3,
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={`${docUrl}${project.Pro_image}`}
                                    alt={project.Title}
                                    sx={{
                                        height: { xs: 100, sm: 114, md: 131.5 },
                                        width: "95%",
                                        objectFit: "contain",
                                        margin: "1rem auto 0 auto",
                                        border: "1px solid #1c2b78",
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        textAlign: "left",
                                        padding: "0.5rem 1rem",
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#1c2b78",
                                            fontSize: { xs: "1rem", sm: "1.1rem", md: "1.3rem" }
                                        }}
                                    >
                                        {project.Title}
                                    </Typography>
                                </CardContent>
                                <CardContent
                                    sx={{
                                        flexGrow: 1,
                                        padding: "0.5rem 1rem",
                                        textAlign: "left",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ lineHeight: 1.5 }}
                                    >
                                        {project.Description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box >
        </>
    );
}
