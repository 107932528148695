import React from "react";
import Banner from "../../Components/Banner";
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    IconButton
} from "@mui/material";
import { LinkedIn } from "@mui/icons-material";

const BoardofTrustees = () => {
    const boardMembers = [
        {
            name: "Dilip Band IAS (Retd.)",
            position: "President",
            designation: "Ex Divisional Commissioner, Pune",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/dilipBan.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Pratap Pawar",
            position: "Vice President",
            designation: "Chairman, Sakal Media Group",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/pratappawar.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Anant Talaulicar",
            position: "Member",
            designation: "Ex Chairman & MD Cummins India",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/ananttalaulicar.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Praveen Roy",
            position: "Member",
            designation: "Head, TTI Division, DST, Govt. of India",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/praveenroy.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Vikram Salunkhe",
            position: "Member",
            designation: "MD, Accurate Gauging & Instruments",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/vikramsalunke.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Pradeep Lokhande",
            position: "Member",
            designation: "Founder Rural Relations",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/pradeeplokhande.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Sohanlal Jain",
            position: "Member",
            designation: "Advocate",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/sohanjain.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Dr. Narayan Hegde",
            position: "Member",
            designation: "Principal Advisor & Managing Trustee, BAIF",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/narayanahegde.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
        {
            name: "Prof. Rajendra Jagdale",
            position: "Member Secretary",
            designation: "Director General & CEO, Science and Technology Park",
            image: `${process.env.REACT_APP_STP_URL}/BoardOfTrustees/rajendrajagdale.png`,
            linkedinUrl: "https://www.linkedin.com/"
        },
    ];

    return (
        <>
            <Banner title={"Board of Trustees"} />
            <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Grid container spacing={4} justifyContent="center">
                        {boardMembers.map((member, index) => (
                            <Grid item xs={12} sm={6} md={4.5} lg={3.5} key={index}>
                                <Card
                                    sx={{
                                        textAlign: "center",
                                        borderRadius: "10px",
                                        boxShadow: 3,
                                        backgroundColor: "#ffffff",
                                        overflow: "hidden",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        px: 2,
                                        pt: 2,
                                        height: 'auto',
                                        minHeight: {xs:'21rem', md:'19rem'}
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        src={member.image}
                                        alt={member.name}
                                        sx={{
                                            width: "120px",
                                            height: "120px",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <CardContent sx={{ pb: 1 }}>
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold", color: "#1C1F35" }}
                                        >
                                            {member.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#1C1F35" }}
                                        >
                                            {member.position}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#666666" }}
                                        >
                                            {member.designation}
                                        </Typography>
                                    </CardContent>
                                    <Box mt="auto">
                                        <IconButton
                                            href={member.linkedinUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ color: "#0077b5", mb: 0 }}
                                        >
                                            <LinkedIn fontSize="large" />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default BoardofTrustees;
