import React, { useState, useEffect } from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import axios from "axios";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import "bootstrap/dist/css/bootstrap.min.css";
import './banner.css'
import "bootstrap/dist/js/bootstrap.bundle.min";

const dummyData = [
  {
    Title: "Tehri Acro Festival 2023",
    Description: "Discover an unparalleled journey filled with adventure",
    Url: "#",
  },
  {
    Title: "Adventure Awaits",
    Description: "Join us for the most thrilling experience of your life",
    Url: "#",
  },
];

export default function BannerSection() {
  const [carouselItems, setCarouselItems] = useState([] || dummyData);
  const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

  useEffect(() => {
    axios
      .get("https://api.scitechpark.org.in/banner/getall")
      .then((response) => {
        setCarouselItems(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching the carousel items:", error);
      });
  }, []);

  useEffect(() => {
      const bootstrap = require("bootstrap");
      const carouselElement = document.getElementById("geoinformaticCarousel");
      if (carouselElement) {
        new bootstrap.Carousel(carouselElement, {
          interval: 3000,
          ride: "carousel",
          pause: "false",
          wrap: true,
        });
      }
    }, []);

  return (
    <Box
      sx={{
        maxWidth: "100%",
        minHeight: { xs: "20vh", lg: "82vh" },
      }}
    >
      <div
        id="carouselExampleAutoplay"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-indicators">
          {carouselItems
            ?.filter((event) => event.Is_deleted === 0)
            ?.map((item, index) => (
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleAutoplay"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : "false"}
                aria-label={`Slide ${index + 1}`}
              />
            ))}
        </div>

        <div className="carousel-inner ">
          {carouselItems
            ?.filter((event) => event.Is_deleted === 0)
            ?.map((item, index) => (
              <div
                key={index}
                className={`carousel-image carousel-item ${index === 0 ? "active" : ""}`}
                style={{
                  backgroundImage: `url(${docUrl}${item?.Image})`,
                  // backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "0",
                  height: '100%',
                  color: "white",
                  position: "relative",
                }}
              >
                <Grid item xs={12} md={6} sx={{ zIndex: 2 }}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={{ xs: "center", md: "flex-start" }} // Center text on small screens
                    justifyContent="center"
                    textAlign={{ xs: "center", md: "left" }}
                    sx={{
                      zIndex: 2,
                      marginLeft: { xs: "0", md: "15rem" },
                      marginTop: { xs: "0", md: "5rem" },
                      padding: { xs: "1rem", md: "0" }, // Padding for better spacing on tablets
                      maxWidth: { xs: "90%", md: "100%" },
                    }}
                  >
                    <Typography
                      variant="body2"
                      letterSpacing={"1.5px"}
                      mb={{ xs: 1, md: 2 }}
                      sx={{
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                        fontSize: { xs: "1rem", md: "1.2rem" }
                      }}
                    >
                      {item.Description}
                    </Typography>
                    <Typography
                      variant="h1"
                      fontSize={{ xs: "1.5rem", md: "3rem" }}
                      fontWeight={"600"}
                      letterSpacing={"1.5px"}
                      sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)" }}
                    >
                      {item.Title}
                    </Typography>
                    {item.Url && (
                      <Button
                        sx={{
                          backgroundColor: "#008bf9",
                          color: "white",
                          fontSize: { xs: "0.8rem", md: "1rem" },
                          fontWeight: "600",
                          padding: { xs: "0.5rem 1rem", md: "0.8rem 1.5rem" },
                          width: { xs: "7rem", md: "10rem" },
                          borderRadius: "10px",
                          position: "relative",
                          overflow: "hidden",
                          mt: { xs: 1, md: 3 },
                          "&:hover": { backgroundColor: "#009bf9" },
                          zIndex: 2,
                        }}
                        size="large"
                        href={item.Url}
                        target="_blank"
                      >
                        Explore <TrendingFlatIcon sx={{ width: "auto" }} />
                      </Button>
                    )}
                  </Box>
                </Grid>

              </div>
            ))}
        </div>

        {/* Carousel controls */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplay"
          data-bs-slide="prev"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderRadius: "50%",
            width: "3rem",
            height: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: "1rem",
            zIndex: 1,
          }}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplay"
          data-bs-slide="next"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            borderRadius: "50%",
            width: "3rem",
            height: "3rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "1rem",
            zIndex: 1,
          }}
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </Box >
  );
}
