/* eslint-disable no-undef */
import React from 'react';
import Banner from './Banner';
import { Box, Container, Typography, List, ListItem, ListItemText, Link, Grid } from '@mui/material';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StartupIndiaCard from './StartupIndiaCard';
import StartupImage from '../../../Media/Startup_India.avif'

const sectionStyle = {
    py: 4,
    backgroundColor: "#ffffff",
    color: "#1C1F35",
};

const sectionStyle2 = {
    py: 4,
    backgroundColor: "#d7e3fc",
    color: "#1C1F35",
};

const startups = [
    {
        Name: "Areete Business Solutions Pvt. Ltd.",
        Description: "IoT based neck sensors specially designed and fitted in a collar that is comfortable for the cattle. It measures parameters like Rumination (%), Body Temperature, Standing/Sitting, Activity level, Heat Cycle, Lameness and GPS Location of the cattle.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Areete+Logo.png`
    },
    {
        Name: "Medvolt Tech Pvt. Ltd.",
        Description: "Medvolt significantly reduces cost and time involved in the early stages of Pharma and Biotech R&D using human-in-loop automation by combining an Al-accelerated technology platform with knowledge discovery and cutting-edge in silico workflows.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/medvolt_ai_logo.jpg`
    },
    {
        Name: "Qtanea Solutions Pvt. Ltd.",
        Description: "Their product makes medical procedures less painful, more comfortable, and less invasive. Their premier vibrating kinetic anesthesia device (Vybe) is compact, easy to use, and non-invasive, provides pain relief by confusing the body's own nerves, dulling or eliminating sharp pain.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Qtanea+Solutions.jpg`
    },
    {
        Name: "Kalaclap Technologies Pvt. Ltd.",
        Description: "Kalaclap is a business networking platform, aggregator and a listing directory which will fill the present gap of making all kinds of creative artists and art gurus available to all of us, in person, at a hyper local level or online from any part of the world.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/kalaclap+logo.jpg`
    },
    {
        Name: "WaveNxD Technologies Pvt. Ltd.",
        Description: "WaveNXD Technologies manufactures ultrasonic spray nozzles and nozzle drive system for the US Nozzle. It produces average droplet size selectable from 14umeter to 100umeter depending on operating frequency of nozzle starting from 40KHz to 180KHz. It allows user to control solution feed rate, droplet size etc.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/WaveNXD+Logo.jpg`
    },
    {
        Name: "Genearche Wellness Pvt. Ltd.",
        Description: "This project aims to develop a personal health record management solution integrated with genomic information. By allowing individuals to store and access their health records, it empowers them to make informed health, nutrition, and lifestyle decisions based on genetic insights.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Genearche+logo.jpg`
    },
    {
        Name: "Rowbotix AgTech Pvt. Ltd.",
        Description: "Rowbotix AgTech is addressing lack of proper equipment and workforce shortage in agriculture. To address the problems in sowing operation, the first product developed is a tractor driven Electronic sowing Implement (ESI). It has the operational flexibility that easily allows farmers to plant two different crops simultaneously.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/rowbotix_agtech_pvt_ltd_logo.jpg`
    },
    {
        Name: "Thermistance Technologies Pvt. Ltd.",
        Description: "Thermistance is a first Indian deep tech Startup in the field of advanced cooling technologies, solving the problem of overheating and failure of electronic devices due to overheating. They are working on advanced passive cooling technology for different applications including EV.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Thermistance+Logo.jpg`
    },
    {
        Name: "AQ Automations Pvt. Ltd.",
        Description: "AQ Automation is a technology-based start-up focusing on solutions to automate certain operations and processes in the garment manufacturing industry which are still performed manually by workers. By using this solution the productivity can be increased and rejection rate can be reduced. The solution deskills the job so the need of highly skilled labor is reduced."
    },
    {
        Name: "Vruddhi Educational and Technological Services Pvt. Ltd.",
        Description: "The Project is dedicated to bridging the talent gap for children with differential needs. At the forefront of their offerings are SpEd@home and SpEd@school, two innovative solutions aimed at revolutionizing special education.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Vruddhi+Logo.svg`
    },
    {
        Name: "Raycura Medical Technologies Pvt. Ltd.",
        Description: "Raycura Medical Technologies Pvt. Ltd. is developing Implantable bone distraction device for treating skeletal deformities and doing skeletal reconstruction. Their product is useful for treating leg length discrepancy, bone loss and congenital and acquired deformities more safely and comfortably.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/raycura_medical_technologies_logo.jpg`
    },
    {
        Name: "Precitorq Transmission Pvt. Ltd.",
        Description: "Precitorq makes innovative products in transmission for e mobility and robotics applications with modern technology catering the various OEMs. Their product portfolio includes automotive gear drives for EVs, harmonic drives, planetary drives and customized drives.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/precitorq+logo.png`
    },
    {
        Name: "IDT Innovation Technologies Pvt. Ltd.",
        Description: "IDT Innovation’s SenseLive is the platform to provide end-to-end IoT solutions for the industry aiming to convert the traditional industries into the smart industries by developing IoT architecture, helping them to improve performance and reduce downtime through remote diagnostics, troubleshooting, condition monitoring capabilities, and predictive maintenance with the help of data management.",
        logo: `https://mystpbucket.s3.ap-south-1.amazonaws.com/cardLogos/StartupIndia/senselive_logo-%2BIDT%2BInnovations-Photoroom.png`
    },
    {
        Name: "Hack-X Security Pvt. Ltd.",
        Description: "HACK-X NODE is a Risk and Vulnerability Management PTaaS product delivering on-demand, continuous and scalable Security Assessments with a unique blend of automated scanning and in-depth manual penetration testing.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Hack-x+logo.png`
    },
    {
        Name: "Maxcalling Software Technologies Pvt. Ltd.",
        Description: "Maxcalling is a mobile app which improves business deal making & improves sales follow up efficiency. This is done by combining client request notes taking & to do task management. It’s a Productivity Toolkit Mobile App for Micro and Small business owners.",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/StartupIndia/Maxcalling+Logo.jpg`
    }
];

const StartupIndia = () => {
    return (
        <>
            <Banner title="Startup India Seed Fund Scheme" />

            {/* Hero Section */}
            <Box sx={{
                ...sectionStyle2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: { xs: 3, sm: 4 },
            }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    padding: 0,
                    px: { xs: 2, sm: 5 },
                }}>
                    <Box sx={{
                        width: { xs: '100%', sm: '50%' },
                        paddingRight: { xs: 0, sm: 5 },
                        textAlign: { xs: 'center', sm: 'left' },
                    }}>
                        <Typography
                            variant="body1"
                            paragraph sx={{
                                color: '#5A7184',
                                lineHeight: 1.6,
                                textAlign: 'justify',
                                marginBottom: 2,
                            }}>
                            The Startup India initiative of the Government of India envisages building a robust Start-up ecosystem in the country for nurturing innovation and providing opportunities to budding entrepreneurs.
                        </Typography>
                    </Box>
                    <Box sx={{
                        width: { xs: '80%', sm: '50%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: { xs: 3, sm: 0 },
                    }}>
                        <img src={StartupImage} alt='startup' style={{
                            width: '100%',
                            maxWidth: '400px',
                            borderRadius: '5%',
                        }} />
                    </Box>
                </Container>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: '#ffffff',
                py: { xs: 4, sm: 6 },
                px: { xs: 3, sm: 12 },
                width: '100%',
            }}>
                <Container sx={{ py: { sm: 2 } }}>
                    <Typography color="#152265" variant="h5" fontWeight="600" align="center" gutterBottom>
                        Objectives
                    </Typography>
                    <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 0 }}>
                        <ListItem sx={{ justifyContent: 'center' }}>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: { xs: '1rem', sm: '1.1rem' },
                                    textAlign: "justify"
                                }}
                                primary="Startup India Seed Fund Scheme (SISFS) aims to provide financial assistance to startups for proof of concept, prototype development, product trials, market entry, and commercialization. This would enable these startups to graduate to a level where they will be able to raise investments from angel investors or venture capitalists or seek loans from commercial banks or financial institutions." />
                        </ListItem>
                    </List>
                </Container>
                <Paper sx={{
                    backgroundColor: '#d7e3fc',
                    padding: '16px',
                    marginBottom: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    width: '85%',
                    mx: 'auto',
                }}>
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: { xs: 16, sm: 18 }, fontWeight: 600, color: "#152265" }}>
                                Eligibility Criteria
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" fontSize={'1.1rem'} sx={{ color: "#5A7184", textAlign: 'justify' }}>
                                <ol>
                                    <li>A startup, recognized by DPIIT, incorporated not more than 2 years ago at the time of application.</li>
                                    <li>
                                        To get DPIIT-recognized, please visit:
                                        <a href="https://www.startupindia.gov.in/content/sih/en/startupgov/startup_recognition_page.html" target="_blank">
                                            Startup India Recognition Page
                                        </a>.
                                    </li>
                                    <li>The startup must have a business idea to develop a product or a service with a market fit, viable commercialization, and scope of scaling.</li>
                                    <li>
                                        The startup should be using technology in its core product or service, business model, distribution model, or methodology to solve the problem being targeted.
                                    </li>
                                    <li>
                                        Preference would be given to startups creating innovative solutions in sectors such as social impact, waste management, water management, financial inclusion, education, agriculture, food processing, biotechnology, healthcare, energy, mobility, defence, space, railways, oil and gas, textiles, etc.
                                    </li>
                                    <li>
                                        Startup should not have received more than Rs 10 lakh of monetary support under any other Central or State Government scheme. This does not include:
                                        <ul>
                                            <li>Prize money from competitions and grand challenges</li>
                                            <li>Subsidized working space</li>
                                            <li>Founder monthly allowance</li>
                                            <li>Access to labs or prototyping facility</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Shareholding by Indian promoters in the startup should be at least 51% at the time of application to the incubator for the scheme, as per Companies Act, 2013 and SEBI (ICDR) Regulations, 2018.
                                    </li>
                                    <li>A startup applicant can avail seed support in the form of a grant and debt/convertible debentures each once as per the guidelines of the scheme.</li>
                                </ol>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Box>

            <Box sx={sectionStyle2}>
                <Container>
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" py={4} mb={3}>
                        Startups Supported under SISFS:
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        {startups.map((startup, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={10}
                                md={6}
                                lg={4}
                                key={index}
                                sx={{ display: 'flex', justifyContent: 'center' }} 
                            >
                                <StartupIndiaCard item={startup} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default StartupIndia;