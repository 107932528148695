import React from "react";
import {
    Modal,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 350, 
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
    overflowY: "auto", // Enables scrolling
    "&::-webkit-scrollbar": {
        width: "6px", // Thin scrollbar
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Color of scrollbar
        borderRadius: "10px", // Rounded scrollbar
    },
};

const YearModal = ({ open, handleClose }) => {
    const yearList = [
        "Shriram Mathe",
        "Vikram Deshmukh",
        "Yogesh Gupta",
        "Shalmali Gupta",
        "Dr. Prerana Tomke",
        "Bhushan Gaikwad",
        "Vaibhav Dama",
        "Saket Dongre",
        "Swapnil Patil",
        "Rajhans Chavan",
        "Dr. Vishesh Kasliwal",
        "Sanjay Bhattacharji",
        "Apeksha Kalari",
        "Rajat Singh",
        "Pitambar Panda",
        "Ritwik Dhar",
        "Harsh Kahndelwal",
        "Abhijeet Bhoyar",
        "Priya Sharma",
        "Gaurav Singh",
    ];

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2" fontWeight={'600'}>
                    Year 5 Grantees
                </Typography>
                <List sx={{ maxHeight: 260, overflowY: "auto" }}>
                    {yearList.map((item, index) => (
                        <ListItem key={index} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Typography variant="body1">•</Typography>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Modal>
    );
};

export default YearModal;
