import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Link,
} from '@mui/material';

export default function MembershipCard({ docUrl, item }) {
    const [showFullText, setShowFullText] = useState(false);

    const toggleText = () => setShowFullText(!showFullText);
    const maxLength = 100;


    const isLongDescription = item.Description.length > maxLength;


    const truncatedDescription = isLongDescription && !showFullText
        ? `${item.Description.slice(0, maxLength)}...`
        : item.Description;

    return (
        <Card
            sx={{
                width: 385,
                p: 3,
                boxShadow: 0,
                borderRadius: 4,
                backgroundColor: '#fafafa',
            }}
        >
            <CardMedia
                component="img"
                height="190"
                image={`${docUrl}${item?.Logo}?w=164&h=164&fit=crop&auto=format`}
                alt={item.Title}
                sx={{ borderRadius: "1.3rem" }}
            />
            <CardContent sx={{ p: 0, pt: 3 }}>
                {/* <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {new Date(item.Start_date).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                    })}
                </Typography> */}
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {item.Name}
                </Typography>
                <Typography variant="body2" color="textSecondary" textAlign={'justify'}>
                    {truncatedDescription}
                    {isLongDescription && (
                        <Link
                            component="button"
                            variant="body2"
                            sx={{ ml: 1, cursor: 'pointer', color: '#0275d8' }}
                            onClick={toggleText}
                        >
                            {showFullText ? "View Less" : "View More"}
                        </Link>
                    )}
                </Typography>
                {
                    item?.Link &&
                    <Link href={item?.Link} target={'_blank'} color="inherit" underline="none" >
                        <Typography
                            variant="body2"
                            sx={{ color: "#0275d8", mt: "0.4rem" }}
                        >
                            Visit link →
                        </Typography>
                    </Link>
                }
            </CardContent>
        </Card>
    );
}
