import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container, Typography } from '@mui/material';
import businessIncubationImage from '../../Media/start-up-1.jpg.webp';

const BusinessIncubationPage = () => {
    return (
        <>
            <Banner title={"Business Incubation"} />
            <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            alignItems: { md: "flex-start" },
                            gap: 2,
                            width: "100%",
                        }}
                    >
                        <Container
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", md: "row" },
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                maxWidth: "1200px",
                                padding: 0,
                                px: { xs: 2, sm: 5 },
                            }}
                        >
                            {/* Text Section */}
                            <Box
                                sx={{
                                    flex: 1, // Makes sure text and image take equal width on larger screens
                                    paddingRight: { xs: 0, sm: 5 },
                                    textAlign: { xs: "center", sm: "left" },
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    paragraph
                                    sx={{
                                        lineHeight: 1.6,
                                        textAlign: "left",
                                        marginBottom: 2,
                                    }}
                                >
                                    Business incubators are programs designed to accelerate the successful
                                    development of entrepreneurial companies through an array of business
                                    support resources and services, developed and orchestrated by incubator
                                    management and offered both in the incubator and through its network of
                                    contacts. Incubators vary in the way they deliver their services, in
                                    their organizational structure, and in the types of clients they serve.
                                </Typography>
                            </Box>

                            {/* Image Section */}
                            <Box
                                sx={{
                                    flex: 1, // Ensures image takes equal space as text
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: { xs: 3, sm: 0 },
                                    width: "100%", // Prevents overflow
                                }}
                            >
                                <img
                                    src={businessIncubationImage}
                                    alt="startup"
                                    style={{
                                        width: "100%", // Ensures it scales properly
                                        maxWidth: "400px",
                                        height: "auto", // Keeps aspect ratio
                                        borderRadius: "5%",
                                    }}
                                />
                            </Box>
                        </Container>
                    </Box>
                    <br />
                    <Typography variant="body1" sx={{ textAlign: "justify" }} paragraph>
                        Science and Technology Park University of Pune (Scitech Park) is one of the first Science and Technology Entrepreneurs Parks (STEPs) in India, set up in 1986, by the National Science and Technology Entrepreneurship Development Board (NSTEDB) of the Department of Science and Technology (DST), Government of India, within the campus of Savitribai Phule Pune University.
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: "justify" }} paragraph>
                        The main objective of the Park is to provide 360-degree incubation support to new, promising, and innovative technology-based startup companies in the areas of IT/ITES, Clean Technology, Renewable Energy, Education, Health, Hygiene, Sanitation, Environment, Waste Management, Agriculture and Food Technology, Pharma, Biotechnology, Electronics and Telecommunication, Cyber Security, Social Incubation, etc. Scitech Park is committed to supporting start-ups and early-stage companies through its expertise in incubator management, mentorship, legal & accounting expertise, and a vast network of contacts in India and abroad.
                    </Typography>
                </Container>
            </Box>
        </>
    );
};

export default BusinessIncubationPage;
