import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import image from '../../../Media/Nidhi_Prayas.jpg';

export default function NidhiEIRCard({ item }) {
    return (
        <Card
            sx={{
                width: { xs: '100%', sm: 300, md: 380 },
                height: '420px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    transform: 'scale(1.05)',
                },
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Title Section */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    padding: { xs: '12px', md: '16px' },
                    borderRadius: '10px 10px 0 0',
                    width: '100%',
                    flexShrink: 0,
                    mb: 1,
                }}
            >
                <Box
                    component="img"
                    src={item?.logo || image}
                    alt={item?.name || 'Logo'}
                    sx={{
                        width: { xs: 60, md: 80 },
                        height: { xs: 60, md: 80 },
                        borderRadius: '50%',
                        mr: 2,
                        objectFit: 'contain',
                        backgroundColor: '#fff'
                    }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', md: '20px' },
                    }}
                >
                    {item?.name}
                </Typography>
            </Box>

            {/* Body Section */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: { xs: '12px', md: '1rem 2rem' },
                    flexGrow: 1,
                    borderRadius: '0 0 10px 10px',
                    overflow: 'hidden',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: { xs: '14px', md: '15px' },
                        lineHeight: 1.5,
                        textAlign: 'justify',
                    }}
                    color="textSecondary"
                >
                    {item?.description}
                </Typography>
            </Box>
        </Card>
    );
}