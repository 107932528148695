import React from "react";
import Banner from "./Banner";
import manxl from "../../../Media/manxl.webp"

const Manxl = () => {
  return (
    <>
      <Banner title={"SciTech StepUp MANXL"} />
      <div style={styles.container}>
        <img src={manxl} alt="NIDHI Accelerator" style={styles.image} />
      </div>
    </>
  );
};

// CSS Styles (Inline Styling)
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "#f5f7fa", // Light background
  },
  image: {
    width: "80%", // Adjust width to fit properly
    maxWidth: "900px",
    height: "auto",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
  },
};

export default Manxl;
