import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import navigation hook

const CustomButton = ({ title, subtitle, status, link }) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: { xs: "90%", sm: "90%", md: "500px" }, // Adjust width dynamically
                height: "120px",
                backgroundColor: "#EDEDED",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                textAlign: "center",
                padding: "10px",
                cursor: "pointer",
                transition: "0.3s",
                "&:hover": {
                    backgroundColor: "#DADADA",
                },
            }}
            onClick={() => navigate(link)}
        >
            <Typography
                sx={{
                    fontSize: { xs: "1.4rem", md: "1.8rem" }, // Adjust font size for small screens
                    fontWeight: "bold",
                    color: "black",
                    letterSpacing: "1px",
                }}
            >
                {title} <span style={{ color: "gray", fontWeight: "600" }}>{subtitle}</span>
            </Typography>
            <Typography
                sx={{
                    fontSize: { xs: "1rem", md: "1.2rem" }, // Adjust status text size
                    fontWeight: "bold",
                    color: "#e88060",
                    marginTop: "5px",
                }}
            >
                {status}
            </Typography>
        </Box>
    );
};

const ButtonContainer = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" }, // Stack on small screens, row on large
                gap: { xs: 2, md: 4 }, // Reduce gap on small screens
                justifyContent: "center",
                alignItems: "center", // Ensure buttons stay centered
                m: { xs: 4, md: 6 },
            }}
        >
            <CustomButton
                title="Scitech"
                subtitle="Climate Runway"
                status="COMPLETED"
                link="/climate-runway"
            />
            <CustomButton
                title="Scitech"
                subtitle="Stepup Manxl"
                status="ONGOING"
                link="/stepup-manxl"
            />
        </Box>
    );
};

export default ButtonContainer;
