import React from 'react'
import Banner from '../../Components/Banner'
import { Box, Container, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import ChandarCard from './ChandarCard';

const ChandarData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/1-1-250x250.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/2-1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/3-1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/5-1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/6-1.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/9.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/10.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/11.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/12.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/13-250x250.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/14.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/15.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/19.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/16.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/18 (1).webp`
    },
]

const videos = [
    {
        title: "Zee 24 Taas(Hours) Coverage",
        src: "https://www.youtube.com/embed/lwKxJNGPOTM",
    },
    {
        title: "Zee News - DNA Special Story Coverage",
        src: "https://www.youtube.com/embed/nIVDRZjFXqc",
    },
];

const NewsCoverageData = [
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/CN-03.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/CN-01-1-250x250.webp`
    },
    {
        image: `${process.env.REACT_APP_STP_URL}/IntegratedVillageDevelopment/Chandar/Cn-02.webp`
    },
]

const Chandar = () => {
    return (
        <Box sx={{ backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
            <Banner title={"Chandar"} />
            <Box sx={{ py: 4 }}>
                <Container>
                    <Typography variant="h4" fontWeight="600" gutterBottom>
                        Scitech Park, Pune inaugurated Development Initiatives for Chandar Village, Maharashtra
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ textAlign: 'justify' }}>
                        Scitech Park, Pune, through its Social Innovation and Intervention Program, initiated critical interventions at Chandar on Monday, 23 April 2018 in the auspicious attendance of Shri. Dilip Band, IAS (Retd.), President, Science and Technology Park, Pune, Shri. Pratap Pawar, Vice-President, Science and Technology Park, Pune and Chairman, Sakal Papers Pvt. Ltd., Cmd. Anand Khandekar, I.N. (Retd.), Member, Science and Technology Park, Pune, Shri. Shivaji Narvekar, General Manager, ECGC Ltd, Mumbai and Dr. Rajendra Jagdale, Director General & CEO, Science and Technology Park, Pune.
                        <br />
                        <br />
                        Also present were senior representatives from donor agencies – GetMy Solutions Pvt. Ltd., Design Display (Mumbai), Vishwjeet Green Power Technology Pvt. Ltd., Sarita Group, Shree Prabhu Petrochemicals Pvt. Ltd., Rural Relations, Sujata Computers Pvt. Ltd., Moovers and Decorators, Mr. Rajnikant Mendhe, Zilla Parishad School Teacher, Chandar and local villagers.
                        <br />
                        <br />
                        The objective of this program is to reduce socio-economic vulnerabilities of the villagers, while enhancing their ability to earn a living, to remain educated and to establish a connection with the world beyond its boundaries.
                        <br />
                        <br />
                        The critical interventions launched were:
                    </Typography>

                    <List sx={{ padding: 0 }}>
                        {[
                            "• Solar LED Lanterns",
                            "• Solar LED Home Lighting and Street Lamps",
                            "• LED TV",
                            "• Solar Water Pump and Controller",
                            "• Water Storage Tank and Pipeline",
                            "• Village Library",
                            "• Multi-purpose Community Hall",
                        ].map((text, index) => (
                            <ListItem
                                key={index}
                                sx={{ padding: "2px 0" }}
                            >
                                <ListItemText primary={text} sx={{ fontSize: "16px", margin: 0 }} />
                            </ListItem>
                        ))}
                    </List>
                </Container>
            </Box>

            <Grid
                container
                pb="2rem"
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {ChandarData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

            <Grid
                container
                spacing={2}
                py={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {videos.map((video, index) => (
                    <Grid item xs={12} sm={8} md={5} key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* Video Title */}
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 600,
                                textAlign: "center",
                                marginBottom: "1rem",
                                px: 2,
                            }}
                        >
                            {video.title}
                        </Typography>

                        {/* Video Container */}
                        <Box
                            sx={{
                                position: "relative",
                                width: "100%",
                                maxWidth: { xs: 350, sm: 450, md: 510 },
                                height: 0,
                                paddingBottom: "56.25%",
                                overflow: "hidden",
                                borderRadius: "10px",
                                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <iframe
                                src={video.src}
                                title={video.title}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                            ></iframe>
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Typography
                variant="h4"
                sx={{ fontWeight: 600, textAlign: "center" }}
            >
                News Coverage
            </Typography>

            <Grid
                container
                py={6}
                justifyContent={"center"}
                sx={{
                    display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                    gap: { xs: "1rem", sm: "1rem", md: "6rem", lg: "3rem" },
                    px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                }}
            >
                {NewsCoverageData.map((item, index) => (
                    <ChandarCard key={index} item={item} />
                ))}
            </Grid>

        </Box>
    )
}

export default Chandar;