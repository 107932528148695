import React from 'react'
import Banner from './Banner'
import Buttons from './Buttons'

const NidhiAccelerator = () => {
  return (
   <>
   <Banner title={"NIDHI Accelerator"}/>
   <Buttons/>
   </>
  )
}

export default NidhiAccelerator
