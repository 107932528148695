import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import fallbackImage from '../../../Media/fallbackImage.jpg'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function AssociationDetail({ spotlightItems }) {
    console.log("spotlightItems", spotlightItems[0]?.Images[0]);

    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = spotlightItems?.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box m='0.8rem auto' sx={{ maxWidth: 370, flexGrow: 1, position: 'relative' }}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {spotlightItems.map((step, index) => (
                    <div key={step.label}>
                        {/* {console.log(`${encodeURI(docUrl + step?.Images[0])}`)} */}

                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                sx={{
                                    height: 190,
                                    maxWidth: 370,
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    backgroundImage: `url(${step?.Images?.[0]
                                            ? encodeURI(docUrl + step.Images[0]) + '?w=164&h=164&fit=crop&auto=format'
                                            : fallbackImage
                                        })`,

                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                }}
                            >
                                <Paper
                                    square
                                    elevation={0}
                                    sx={{
                                        width: '100%',
                                        bgcolor: 'rgba(0, 0, 0, 0.6)',
                                        color: 'white',
                                        padding: '8px 16px',
                                    }}
                                >
                                    <Typography>{step?.Title}</Typography>
                                </Paper>
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 8,
                                        display: 'flex',
                                        gap: 1,
                                    }}
                                >
                                    <Button
                                        size="small"
                                        onClick={handleBack}
                                        disabled={activeStep === 0}
                                        sx={{
                                            bgcolor: 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            minWidth: 'auto',
                                            borderRadius: 0
                                        }}
                                    >
                                        <KeyboardArrowLeft />
                                    </Button>
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                        sx={{
                                            bgcolor: 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            minWidth: 'auto',
                                            borderRadius: 0
                                        }}
                                    >
                                        <KeyboardArrowRight />
                                    </Button>
                                </Box>
                            </Box>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
        </Box>
    );
}

export default AssociationDetail;
