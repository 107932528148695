import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Button,
    Link,
} from "@mui/material";
import axios from "axios";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

export default function Events() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const docUrl = "https://mystpbucket.s3.ap-south-1.amazonaws.com/";

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(
                    "https://api.scitechpark.org.in/events/getallevent"
                );
                const allData = response?.data?.result;
                if (Array.isArray(allData)) {
                    const latestData = allData.slice(-3).reverse(); 
                    setEvents(latestData);
                } else {
                    console.error("Expected an array but got:", allData);
                }
            } catch (error) {
                console.error("Error fetching events:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    return (
        <Box sx={{ backgroundColor: "#0a2d7f", py: 6, px: 2, color: "white" }}>
            <Typography
                variant="h6"
                textAlign="center"
                mb={1.5}
                sx={{ color: "#008bf9" }}
            >
                Explore Events
            </Typography>
            <Typography
                variant="h4"
                textAlign="center"
                mb={4}
                sx={{ fontWeight: "bold" }}
            >
                Discover the Undiscovered
            </Typography>
            {loading ? (
                <Typography textAlign="center" mt={2}>
                    Loading...
                </Typography>
            ) : (
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        flexWrap: "wrap",
                        px: { xs: 1, sm: "2rem", md: "6rem" }, 
                    }}
                >
                    {events
                        ?.filter((event) => !event.Is_deleted)
                        .map((item, index) => (
                            <Grid item xs={12} sm={6} md={5} lg={3.9} key={index}>
                                <Card
                                    sx={{
                                        maxWidth: "100%",
                                        backgroundColor: "#fff",
                                        borderRadius: "10px",
                                        color: "#000",
                                        height: "100%",
                                        boxShadow: 3,
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        image={`${docUrl}${item?.Images[0]}`}
                                        alt={item.Title}
                                        sx={{
                                            height: 140,
                                            objectFit: "contain",
                                            padding: "1rem 0rem",
                                            margin: "auto",
                                        }}
                                    />
                                    <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            {new Date(item.Start_date).toLocaleDateString(
                                                "en-US",
                                                {
                                                    month: "long",
                                                    day: "numeric",
                                                    year: "numeric",
                                                }
                                            )}
                                            {" "}
                                            <CalendarMonthIcon fontSize="small" />
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{ fontWeight: "bold", mb: 1, color: "#1c2b78" }}
                                        >
                                            {item.Title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ flexGrow: 1 }}>
                                            {item.Description}
                                        </Typography>
                                        <Box sx={{ mt: "auto" }}>
                                            <Link href={item?.Link} color="inherit" underline="none">
                                                <Typography
                                                    variant="body2"
                                                    sx={{ color: "#0275d8", mt: "0.4rem" }}
                                                >
                                                    View More →
                                                </Typography>
                                            </Link>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            )}
            <Box textAlign="center" mt={6}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "white",
                        color: "#0a2d7f",
                        padding: "1rem 3rem",
                        fontWeight: 550,
                        fontSize: "14px",
                        textTransform: "none",
                        borderRadius: "10px",
                        ":hover": {
                            backgroundColor: "#f5f5f5",
                        },
                    }}
                    onClick={() => window.open(`/events`, "_self")}
                >
                    More Events
                </Button>
            </Box>
        </Box>
    );
}
