import React from 'react';
import Banner from '../../Components/Banner';
import { Box, Container, Typography, Grid } from '@mui/material';

const TechBasedProjects = () => {
    return (
        <>
            <Banner title={"Technology Based Skill Training Projects"} fontSize={{ xs:'1.8rem', md: '4rem' }} />

            <Box sx={{ py: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                <Container>
                    <Typography variant="h5" fontWeight="600" gutterBottom textAlign={'center'}>
                        Scitech Park has created Technology Business Incubator at SWPH & RC, Wanowrie, Pune for Differently Abled Persons
                    </Typography>
                    <Typography variant="body1" paragraph textAlign={'justify'}>
                        which has modern training infrastructure with an intention to impart skill training in various technology sectors and provide them an opportunity of livelihood, self-employment and better standing the society. Skill training in the sector Renewable Energy was imparted to 19 candidates from SWPH & RC which comprised of 10 boys and 9 girls along with these candidates three instructors also participated in the training activity.
                        <br />
                        <br />
                        Another skill training program was arranged in the New Emerging Technologies like Computer and Mobile. The program focused on assembly, repair and maintenance of Desktop Computers and repair and maintenance of Mobile Handsets. The program was scheduled for one month. Two batches of 20 students each (total 40 students) from SWPH & RC, Wanowrie, Pune, who had appeared for SSC board exam participated in the training.
                    </Typography>
                </Container>
            </Box>

            {[
                {
                    text: "Scitech Park, Pune and District Planning Committee, Jalgaon in association with National Rural Livelihood Mission, Jalgaon conducted a one-day training programme on Supplementary Income Opportunities for Women Self-Help Groups based on Innovative Technology for Soy milk and Allied Product preparation. Approximately, 245 women from 25 SHGs were trained during the programme.",
                    image: `${process.env.REACT_APP_STP_URL}/techBasedSkillTrainingData/6-3.webp`,
                },
                {
                    text: "Science and Technology Park, Pune and District Planning Committee, Sangli in association with District Rural Development Agency, Sangli had conducted a one-day training programme on Supplementary Income Opportunities for Women Self-Help Groups based on Innovative Technology for Soy milk and Allied Product preparation. Approximately 230 women from 20 Self-Help Groups attended the training programme.",
                    image: `${process.env.REACT_APP_STP_URL}/techBasedSkillTrainingData/3-w800-h800.webp`,
                },
                {
                    text: "Scitech Park along with Mukul Madhav Foundation, Pune, Finolex Industries Ltd, Pune and FICCI FLO Pune Chapter had conducted a training programme for 412 women farmers (100 widow and 312 women farmers) from Osmanabad on Soyamilk and allied products preparation with the help of innovative and portable Soymate Machine. This training programme was conducted with an aim to develop a cluster for producing soyamilk and other allied products like tofu, soya sheera, soya chapatis, fortified and flavored milk etc.",
                    image: `${process.env.REACT_APP_STP_URL}/techBasedSkillTrainingData/1-1-1.webp`,
                },
                {
                    text: "Scitech Park, Finolex Industries Ltd, Pune along with its CSR partners Mukul Madhav Foundation Pune and Rayat Shikshan Sanstha’s, Arts, Science and Commerce College, Mokhada, jointly conducted an extensive Scitech Soymate Training and Distribution Programme at the nodal center, ASCC Mokhada, Dist. Palghar, Maharashtra for 10 Zilla Parishad Primary Schools, Mokhada.",
                    image: `${process.env.REACT_APP_STP_URL}/techBasedSkillTrainingData/3-3.webp`,
                }
            ].map((item, index) => (
                <Box key={index} sx={{ pb: 4, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
                    <Container>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={12} md={6} order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}>
                                <Typography
                                    variant="body1"
                                    fontSize={'1.1rem'}
                                    paragraph
                                    sx={{ lineHeight: 1.6, textAlign: 'justify' }}
                                >
                                    {item.text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <img
                                        src={item.image}
                                        alt="EIR"
                                        style={{
                                            width: '100%',
                                            maxWidth: '400px',
                                            borderRadius: '5%',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            ))}
        </>
    );
};

export default TechBasedProjects;
