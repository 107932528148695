import React from 'react';
import { Card, Divider, Typography, Button, Box } from '@mui/material';
import image from '../../../Media/Nidhi_Prayas.jpg';

export default function NidhiPrayasCard({ item }) {
    return (
        <Card
            sx={{
                width: { xs: '90%', sm: '85%', md: '90%' },
                maxWidth: 350,
                height: 'auto',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    transform: { md: 'scale(1.03)' },
                },
                backgroundColor: '#1C2B77',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                margin: { xs: '1rem auto', md: '1rem 0' },
            }}
        >
            {/* Title */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    padding: { xs: '8px 12px', sm: '2px 12px' },
                    borderRadius: '4px',
                    mb: 1,
                    mt: 1,
                    width: '100%',
                }}
            >
                <Box
                    component="img"
                    src={item?.logo || image}
                    alt={item?.logo}
                    sx={{
                        width: { xs: 60, sm: 70, md: 80 },
                        height: { xs: 60, sm: 70, md: 80 },
                        borderRadius: '50%',
                        mr: 2,
                        objectFit: 'contain',
                        backgroundColor: '#fff'
                    }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', sm: '18px', md: '20px' },
                    }}
                >
                    {item.name}
                </Typography>
            </Box>

            {/* Body */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: { xs: '0.5rem 1rem', sm: '1rem 2rem' },
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: { xs: '14px', sm: '15px' },
                        lineHeight: 1.6,
                        textAlign: 'justify',
                        mb: 1,
                        mt: 1,
                    }}
                    color="textSecondary"
                >
                    {item.description}
                </Typography>
            </Box>

            {/* Footer */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    py: '0.5rem',
                    borderRadius: '4px',
                    flexDirection: 'row',
                    mt: 'auto',
                    width: '100%',
                }}
            >
                <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '14px',
                            color: '#fff',
                            fontWeight: 'bold',
                        }}
                    >
                        Products
                    </Typography>
                </Box>

                <Divider
                    orientation="vertical"
                    sx={{
                        height: '40px',
                        borderColor: '#fff',
                        mx: 2,
                    }}
                />

                <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <Button
                        sx={{
                            fontSize: '14px',
                            textTransform: 'none',
                            color: 'white',
                            mr: '10px',
                        }}
                        onClick={() => window.open(item?.website, '_blank')}
                    >
                        Visit Website ➞
                    </Button>
                </Box>
            </Box>
        </Card>
    );
}