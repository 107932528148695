import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import image from '../../../Media/Nidhi_SSS.jpg';

export default function StartupIndiaCard({ item }) {
    return (
        <Card
            sx={{
                width: { xs: '90%', sm: '85%', md: '90%' }, 
                maxWidth: 350,
                height: 'auto',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)',
                    transform: { md: 'scale(1.03)' },
                },
                backgroundColor: '#1C2B77',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                margin: { xs: '1rem auto', md: '1rem 0' },
            }}
        >
            {/* Title Section */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1C2B77',
                    padding: { xs: '8px 12px', md: '2px 12px' },
                    borderRadius: '4px',
                    mb: 1,
                    mt: 1,
                    width: '100%',
                }}
            >
                <Box
                    component="img"
                    src={item?.logo || image}
                    alt={item.Name}
                    sx={{
                        width: { xs: 60, md: 80 },
                        height: { xs: 60, md: 80 },
                        borderRadius: '50%',
                        mr: 2,
                        objectFit: 'contain',
                        backgroundColor: '#fff'
                    }}
                />
                <Typography
                    variant="h5"
                    sx={{
                        color: '#fff',
                        fontWeight: 'bold',
                        fontSize: { xs: '16px', md: '20px' },
                    }}
                >
                    {item.Name}
                </Typography>
            </Box>

            {/* Description Section */}
            <Box
                sx={{
                    backgroundColor: '#fff',
                    padding: { xs: '0.5rem 1rem', md: '1rem 2rem' },
                    flexGrow: 1,
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: { xs: '14px', md: '15px' },
                        lineHeight: 1.6,
                        textAlign: 'justify',
                        mb: 1,
                        mt: 1,
                    }}
                    color="textSecondary"
                >
                    {item.Description}
                </Typography>
            </Box>
        </Card>
    );
}
