/* eslint-disable no-undef */
import React from 'react';
import Banner from './Banner';
import { Box, Container, Typography, List, ListItem, ListItemText, Link, Grid } from '@mui/material';
import NidhiPrayasCard from './NidhiPrayasCard';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrayasImage from '../../../Media/Nidhi_Prayas.jpg';
import YearButtons from './YearButtons';

const sectionStyle = {
    py: 4,
    backgroundColor: "#ffffff",
    color: "#1C1F35",
};

const sectionStyle2 = {
    py: 4,
    backgroundColor: "#d7e3fc",
    color: "#1C1F35",
};

const startups = [
    {
        name: "BiomarkIQ Scientific Technologies Pvt. Ltd.",
        description: "BiomarkIQ specializes in innovative diagnostic solutions, such as the VarSee clinical inference platform, designed for the early detection of lung cancer. By integrating biomarker data from multiple sources, the platform ranks biomarker combinations to generate comprehensive diagnostic scores. VarSee aims to revolutionize early cancer screening by offering a software-driven blood test solution, which could improve survival rates through timely intervention.",
        website: "https://www.biomarkiq.com/",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/biomarkiq_logo.jpg`
    },
    {
        name: "Mars Health-Tech Pvt. Ltd.",
        description: "Mars Health-Tech is focused on creating revolutionary diagnostic tools. Their key innovation, SALIPREG, simplifies pregnancy detection by using saliva as a biomarker instead of traditional biofluids like urine. This breakthrough offers a more hygienic, convenient, and quick testing method, reducing the steps involved in traditional tests and ensuring privacy. It represents a shift in at-home pregnancy diagnostics with improved usability.",
        website: "https://marshealthtech.com/",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/MARS+Healthtech.jpg`
    },
    {
        name: "Leotechsa Robotics Pvt. Ltd.",
        description: "Leotechsa Robotics provides advanced robotic solutions for upper limb rehabilitation. Their system combines mechatronics, robotics, and software expertise to offer personalized rehabilitation experiences, incorporating AI-driven algorithms to customize therapy sessions. The device is designed to be portable and lightweight, offering a cost-effective and efficient solution for patients recovering from upper limb injuries or surgeries.",
        website: "http://leotechsa.com",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/Leotechsa+Robotics.png`
    },
    {
        name: "3Dincise Engineering Pvt. Ltd.",
        description: "Traditional solar dryers used in various industries lack advanced functionalities and automation, leading to inefficiencies in the drying process and suboptimal product quality. The Retro Fitment Kit for Smart IoT-based Solar Dryers is designed to upgrade existing solar dryers, making them smarter and more automated. By adding retro-fitment components and IoT features, the kit enables real-time data collection, analysis, and remote monitoring.",
        website: "https://solarbasket.in/",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/3dincise.jpg`
    },
    {
        name: "Daji Industries Pvt. Ltd.",
        description: "One of the major challenges faced by farmers and harvest contractors during sugarcane harvesting is the acute shortage of labor, leading to inefficiencies and delays. This innovative solution addresses these pressing challenges through a small, compact attachment which seamlessly integrates with existing tractors, ensuring full capacity utilization and flexibility in harvesting.",
        website: ""
    },
    {
        name: "Swapratiraksha Arogya Pvt. Ltd.",
        description: "The existing FDA-approved herbal mix for cell disorders is not easily consumable by the masses due to its form factor. There is a need to convert this herbal mix into a tablet form that retains its efficacy, potency, and safety while ensuring a longer shelf life. This project focuses on researching and manufacturing an FDA-approved herbal mix into tablet form. The innovation lies in the conversion of the herbal mix into a mass-market tablet without compromising its efficacy, potency, or safety.",
        website: ""
    },
    {
        name: "Octosense Pvt. Ltd.",
        description: "The LAMP Device market in India is dominated by imported machines, leading to high costs, limited service support, and technical issues such as closed-loop systems and optical optimization. The device proposed by Octosense would incorporate Peltier elements for rapid and accurate temperature cycling, temperature sensors for precise monitoring, microprocessors with control algorithms for temperature regulation, and user-friendly interfaces for easy operation.",
        website: "",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/OctoSense.png`
    },
    {
        name: "Agmove Robotics India Pvt. Ltd.",
        description: "The proposed innovation involves eliminating walk-behind equipment and introducing remotely controlled versatile farm equipment. This innovative technology allows farmers to operate their equipment from a distance, reducing physical exertion, fatigue, and the risk of injuries. The equipment will be designed to perform tasks like spraying, weeding, and harvesting efficiently and autonomously, improving overall farm productivity.",
        website: "https://agmove.in/",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/AGMOVE.png`
    },
    {
        name: "AI2AW Systems Pvt. Ltd.",
        description: "The proposed innovation introduces a novel cricket bat inspection system utilizing advanced technologies such as robotics, sensor analytics, computer vision, artificial intelligence, and cloud technologies. This system includes a robotic arm equipped with a hammer for inspection, embedded systems with sensor analytics for data collection, and AI-based descriptive and predictive analysis for qualitative assessment and grading.",
        website: ""
    },
    {
        name: "DB Potential LLP",
        description: "The current dependence on foreign players for electronic speed governor systems in India's engine industry poses several challenges, DB Potential LLP is developing tailored solutions for the Indian market, focusing initially on mining and construction equipment and later expanding to marine, gen-set, and military applications. Retrofitting mechanical governors with electronic systems will also be a key focus, improving overall engine performance and reliability.",
        website: "",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/DB+Potential+LLP.jpg`
    },
    {
        name: "Avisa Automotive Pvt. Ltd.",
        description: "Avisa Automotive - developing revolutionary electric utility vehicle designed to empower street vendors. Its key features include modular capabilities for diverse businesses, on-vehicle cooking facilities, cold storage, and beverage preparation. The vehicle's user-friendly design breaks gender stereotypes. Innovative aspects such as data analytics provide valuable insights into customer preferences and inventory management, optimizing business strategies for increased profitability.",
        website: "",
        logo: `${process.env.REACT_APP_STP_URL}/cardLogos/Prayas/Avisa+Autonotive.jpg`
    }
];
const NidhiPrayasProgram = () => {

    return (
        <>
            <Banner title="NIDHI PRAYAS" letterSpacing='5px' />
            <Box sx={{
                ...sectionStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: { xs: 3, sm: 4 },
            }}>
                <Container sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    padding: 0,
                    px: { xs: 2, sm: 5 },
                }}>
                    <Box sx={{
                        width: { xs: '100%', sm: '50%' },
                        paddingRight: { xs: 0, sm: 5 },
                        textAlign: { xs: 'center', sm: 'left' },
                    }}>
                        <Typography
                            variant="body1"
                            paragraph sx={{
                                color: '#5A7184',
                                lineHeight: 1.6,
                                textAlign: 'justify',
                                marginBottom: 2,
                            }}>
                            NIDHI-PRAYAS Program aims at providing prototype funding to convert ideas into prototypes for young and aspiring innovators. Innovators should use the PRAYAS grant primarily to convert their ideas into a product prototype with potential for commercialization. The IP generated, if any, should vest with the Innovator or the Startup. The maximum funding support to an innovator/startup will be up to 10 lakhs; however, the final amount is subject to the approval of the Monitoring Committee of a PRAYAS center.
                        </Typography>
                    </Box>
                    <Box sx={{
                        width: { xs: '80%', sm: '50%' },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: { xs: 3, sm: 0 },
                    }}>
                        <img src={PrayasImage} alt='startup' style={{
                            width: '100%',
                            maxWidth: '400px',
                            borderRadius: '5%',
                        }} />
                    </Box>
                </Container>
            </Box>
            <Box sx={sectionStyle2}>
                <Grid container spacing={12} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12}>
                        <Typography
                            variant="h3"
                            sx={{ mt: 1, color: "#0099DE", fontWeight: 'bold', textAlign: 'center' }}
                        >
                            Financial Support
                        </Typography>

                        <Typography
                            variant="h6"
                            sx={{ color: "#364356", fontWeight: 'normal', mt: 0.5, textAlign: 'center' }}
                        >
                            up to
                        </Typography>

                        <Typography
                            variant="h2"
                            sx={{ color: "black", fontWeight: 'bold', mt: 0.5, textAlign: 'center' }}
                        >
                            &#8377; 10 Lakhs
                        </Typography>


                    </Grid>
                </Grid>
            </Box>

            <YearButtons />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#ffffff',
                    // py: 4,
                    // px: { xs: 2, md: 12 },
                    width: '100%',
                    // maxWidth: '900px',
                    margin: 'auto',
                }}
            >
                <Box
                    width="100%"
                    m="auto"
                    sx={{
                        backgroundColor: '#d7e3fc',
                        py: { md: 3 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" gutterBottom>
                        Objectives
                    </Typography>
                    <List
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: 'center',
                            alignItems: "center",
                            padding: 0,
                            color: "#364356"
                        }}
                    >
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="1. To enable translation of an innovative idea to a prototype."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="2. To provide a platform for faster experimentation and modification of approaches in the idea-to-market journey."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="3. To indigenously generate innovative solutions relevant to local and global problems."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="4. To attract a large number of youth demonstrating problem-solving zeal and abilities."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="5. To work on new technology/knowledge/innovation-based startups."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="6. To enhance the pipeline in terms of quality and quantity of innovative startups to incubators."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                        <ListItem sx={{ justifyContent: "center" }}>
                            <ListItemText
                                primary="7. To build a vibrant innovation ecosystem by establishing a network between innovators, academia, mentors, and incubators."
                                primaryTypographyProps={{ fontSize: "1.1rem", textAlign: 'justify' }}
                            />
                        </ListItem>
                    </List>

                </Box>
                <Paper sx={{
                    backgroundColor: '#d7e3fc',
                    padding: '16px',
                    marginBottom: '16px',
                    marginTop: '3rem',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    width: '80%',
                    // maxWidth: '600px',
                }}>
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Eligibility Criteria
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" fontSize={'1.1rem'} sx={{ color: "#5A7184", textAlign: 'justify' }}>
                                <ol>
                                    <li><strong>An individual innovator without having a startup or founder of a startup can apply for the PRAYAS grant.</strong></li>
                                    <li><strong>In case of an applicant being an individual innovator without having a startup applying for PRAYAS:</strong>

                                        <ul>
                                            <li>The applicant has to be an Indian Citizen with a government-approved proof of nationality such as a valid passport, voter’s ID, etc.</li>
                                            <li>The applicant should be a minimum of 18 years of age, as of the date of application.</li>
                                            <li>If the applicant works in a team with other innovators, there has to be an agreement among the team about the lead innovator who would apply, and the funds will be transferred to the lead innovator’s account (after selection). IP generated would vest with the innovator or with the team as per the agreement, and it would be further taken for commercialization.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>In case of an applicant being a co-founder of a Startup applying for PRAYAS:</strong>
                                        <ul>
                                            <li>The applicant has to be an Indian citizen with a government-approved proof of nationality such as a valid passport, voter’s ID, etc.</li>
                                            <li>The applicant should be a minimum of 18 years of age, as of the date of application.</li>
                                            <li>The startup should be incorporated in India with a minimum of 51% of its equity held by Indian citizens. The startup should not be more than 7 years of existence.</li>
                                            <li>The startup wants to develop the prototype for a new product, for which they have not received support from any other funding agency.</li>
                                            <li>The startup should not have annual turnover exceeding Rs. 25 lakhs for any of the financial years since its inception.</li>
                                            <li>IP generated would vest with the startup.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>PRAYAS grant is a Proof-of-concept grant for converting idea and innovations into a prototype. Proposals for products that have crossed the prototype level are not eligible for support under NIDHI-PRAYAS.</strong>
                                        <ul>
                                            <li>The ideas and innovations should be based on technology and science.</li>
                                            <li>The product prototype has to be a physical product.</li>
                                            <li>Applicants can be individual innovators or early-stage startups.</li>
                                            <li>Applicants will have ownership of the project know-how or IP of the proposed products or the right to use know-how or IP if it is owned by a third party.</li>
                                            <li>The prototype must be capable of being completed within 18 months.</li>
                                            <li>Innovators should have technical skills or background.</li>
                                            <li>The idea or innovations should have a roadmap toward commercialization.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>The priority technology areas are:</strong> manufacturing, agriculture, healthcare, clean-tech, energy, water, and Internet of Things (IoT). Other technology areas may also be considered based on the strength or merit of the idea.</li>
                                    <br />
                                    <li><strong>Preference may be given to innovators:</strong>
                                        <ul>
                                            <li>Having bootstrapped or have co-investment commitments.</li>
                                            <li>Having a clear roadmap for commercialization or startup creation.</li>
                                            <li>Women innovators.</li>
                                            <li>Young innovators.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Additional terms for innovators in employment or students with any R&D organization or academic institution:</strong>
                                        <ul>
                                            <li>The innovators in employment or pursuing students have to seek the NOC (No Objection Certificate) from their organization or institution. The NOC should include:
                                                <ul>
                                                    <li>Permission to apply for PRAYAS and extending sufficient time to work on the PRAYAS project.</li>
                                                    <li>Permission that IP generated would vest with the innovator.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Notwithstanding the above, the following will not be considered under NIDHI-PRAYAS:</strong>
                                        <ul>
                                            <li>Projects related to pure software development, e-commerce, service solutions, and app-based solutions.</li>
                                            <li>Funding research or student internships in academic institutions or R&D organizations.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>

                <Paper sx={{
                    backgroundColor: '#d7e3fc',
                    padding: '16px',
                    marginBottom: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    width: '80%',
                    // maxWidth: '600px',
                }}>
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Guideline for Usage of Funds
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" fontSize={'1.1rem'} sx={{ color: "#5A7184", textAlign: 'justify' }}>
                                <ol>
                                    <li><strong>Eligible Usage:</strong>
                                        <ul>
                                            <li>Outsourcing Charges for R&D/Design Engg./Consultancy/Testing/Expert cost</li>
                                            <li>Raw material/Consumables/Spares</li>
                                            <li>Fabrication/Synthesis charges of working model or process</li>
                                            <li>Business Travel and Event participation Fees (Ceiling 10% of approved project cost)</li>
                                            <li>Patent filing Cost (PCT - Ceiling 10% of approved project cost)</li>
                                            <li>Contingency (Ceiling 10% of approved project cost)</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Exclusions - Usage of funds will not be allowed for:</strong>
                                        <ul>
                                            <li>To pay the grantee or their relatives.</li>
                                            <li>To repay pre-existing loans or commitments of the innovator.</li>
                                            <li>To pay usage charges to the Incubator from the PRAYAS grant. Grantee can use other funds to pay any such charges, if any.</li>
                                            <li>To pay rent of own accommodation, creation of infrastructure facility like shed, etc.</li>
                                            <li>As a stop-gap arrangement to support any other professional pursuits.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>

                <Paper sx={{
                    backgroundColor: '#d7e3fc',
                    padding: '16px',
                    marginBottom: '3rem',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    width: '80%',
                    // maxWidth: '600px',
                }}>
                    <Accordion sx={{ backgroundColor: 'transparent', border: 'none', boxShadow: 0 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 600, color: "#152265" }}>
                                Terms and Conditions
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1"
                                fontSize={'1.1rem'} sx={{ color: "#5A7184", textAlign: 'justify' }}>
                                <ol>
                                    <li><strong>Grant Amount and Support:</strong>
                                        <ul>
                                            <li>PRAYAS support is in the form of a grant, which will be a maximum of Rs. 10 lakhs.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Decision Process:</strong>
                                        <ul>
                                            <li>PRAYAS applications will be subject to the decision of the committee members of the PC where the innovator has applied.</li>
                                            <li>The decision of approval, rejection, or reduced grant amount by the committee shall remain final and binding on the innovator.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Commitment to Prototype Development:</strong>
                                        <ul>
                                            <li>The applicant will be fully committed to working towards the prototype development for which the support is being sought, with an intention to commercialize the product.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Project Duration:</strong>
                                        <ul>
                                            <li>The project duration is 18 months.</li>
                                            <li>Prototype must be completed within a maximum of 18 months from receiving the first tranche of the grant.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Unutilized Grant Return:</strong>
                                        <ul>
                                            <li>For incomplete projects, the innovator must return the unutilized amount of the grant.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>IP Ownership for Existing Ventures:</strong>
                                        <ul>
                                            <li>If the innovator has already started a venture, they should assign the IP ownership rights to the startup.</li>
                                        </ul>
                                    </li>
                                    <br />
                                    <li><strong>Multiple Applications and Support Limitations:</strong>
                                        <ul>
                                            <li>Innovators or startup teams cannot avail NIDHI PRAYAS support more than once nor from more than one center.</li>
                                            <li>Innovators shall also not be supported in parallel under NIDHI-EIR and NIDHI-PRAYAS.</li>
                                            <li>Innovators will provide a declaration at the time of application indicating that they have not received support under NIDHI-PRAYAS for the same idea or under NIDHI-EIR.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </Box>

            <Box sx={sectionStyle2}>
                <Container >
                    <Typography color="#152265" variant="h4" fontWeight="600" align="center" py={2} mb={3}>
                        Supported Startups under NIDHI-PRAYAS
                    </Typography>
                    <Grid container spacing={3} justifyContent="center">
                        {startups.map((startup, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={10}
                                md={6}
                                lg={4}
                                key={index}
                                sx={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <NidhiPrayasCard item={startup} />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

        </>
    );
};

export default NidhiPrayasProgram;
