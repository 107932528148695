import React, { useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Link,
    Card,
    List,
    ListItem,
    ListItemText,
    Divider,
    Fab,
} from "@mui/material";
import './index.css';

const menuItems = [
    "Agri and Food Processing Technology",
    "Cyber Security",
    "Data Centers",
    "Edtech",
    "Electronics and Telecommunication",
    "Health",
    "IT and IT Enabled Services",
    "Manufacturing",
    "Mobile Computing",
    "Open Source and Open Platform Technology",
    "Pharma and Biotechnology",
    "Project Management",
    "Remote Sensing and GIS Services",
    "Renewable Energy and Clean Technology",
    "Social Incubation",
];


const slugs = {
    "Open Source and Open Platform Technology": "open-source-platform",
    "Renewable Energy and Clean Technology": "renewable-energy-clean-tech",
    "Pharma and Biotechnology": "pharma-biotech",
    "Agri and Food Processing Technology": "agri-food-processing",
    "Mobile Computing": "mobile-computing",
    "IT and IT Enabled Services": "it-ites",
    "Project Management": "project-management",
    "Data Centers": "data-centers",
    "Social Incubation": "social-incubation",
    "Marine Services": "marine-services",
    "Cyber Security": "cyber-security",
    "Remote Sensing and GIS Services": "geoinformatics",
    "Education": "education",
    "Electronics and Telecommunication": "electronics-telecom",
};


const content = {
    "Agri and Food Processing Technology": `Supporting startups in agriculture and food processing technology, aiming to enhance food security, sustainability, and innovation in farming practices.`,

    "Cyber Security": `Providing incubation for startups working in cyber security, with a focus on developing solutions to protect data, systems, and networks from evolving threats.`,

    "Data Centers": `Startups working in data center technologies are incubated to develop scalable and efficient solutions for managing large-scale data and cloud infrastructures.`,

    "Edtech": `This section supports startups innovating in education, with a focus on creating new methods, platforms, and technologies to improve learning outcomes.`,

    "Electronics and Telecommunication": `Incubating startups in the electronics and telecommunication sectors, driving innovation in communication technologies and electronic devices.`,

    "Health": `Focused on healthcare technology, this section supports startups working on innovations in medical devices, telemedicine, diagnostics, and public health solutions to improve patient care and wellness.`,

    "IT and IT Enabled Services": `Startups focused on information technology and IT-enabled services are provided with resources to develop cutting-edge software and digital solutions.`,

    "Manufacturing": `Focused on advanced manufacturing, this section supports startups working on innovations in automation, smart factories, and sustainable production processes to enhance efficiency and productivity.`,

    "Mobile Computing": `This section focuses on mobile computing advancements, incubating startups that innovate in mobile technology and mobile-first solutions.`,

    "Open Source and Open Platform Technology": `This section supports startups developing open-source solutions and open platform technologies, driving innovation in software and hardware ecosystems.`,

    "Pharma and Biotechnology": `Incubating startups in the fields of pharmaceuticals and biotechnology, with a focus on groundbreaking research and innovations in healthcare and medicine.`,

    "Project Management": `Helping startups in project management, offering support for developing tools and techniques that improve project execution and efficiency.`,

    "Remote Sensing and GIS Services": `Startups in the geoinformatics field are incubated here, developing geographic information systems (GIS) and spatial data solutions for various applications.`,

    "Renewable Energy and Clean Technology": `Focused on fostering startups that innovate in the renewable energy sector, this program promotes clean technology solutions for a sustainable future.`,

    "Social Incubation": `This program supports social entrepreneurship, incubating startups focused on creating social impact and sustainable community development solutions.`,
};


const images = {
    "Agri and Food Processing Technology": "https://img.freepik.com/free-photo/close-up-scientific-research-laboratory-with-glass-test-tubes-flask-microscope-sapling_482257-4582.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Cyber Security": "https://img.freepik.com/free-photo/person-working-html-computer_23-2150038843.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Data Centers": "https://img.freepik.com/free-vector/data-center-isometric-composition_1284-18847.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Edtech": "https://img.freepik.com/free-vector/lovely-education-concept-with-flat-design_23-2147913131.jpg?ga=GA1.1.1417762767.1732692123",
    "Electronics and Telecommunication": "https://img.freepik.com/free-vector/wireless-technology-concept_1284-38154.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Health": "https://img.freepik.com/free-vector/medical-background-design_1212-116.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "IT and IT Enabled Services": "https://img.freepik.com/free-vector/mobile-devices-infographics_1284-4074.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Manufacturing": "https://img.freepik.com/free-vector/industrial-augmented-reality-isometric-composition_1284-29750.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Mobile Computing": "https://img.freepik.com/free-vector/data-storage-service-with-tablet-smartphone_1284-32730.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Open Source and Open Platform Technology": "https://img.freepik.com/free-vector/couple-playing-with-virtual-reality_18591-52363.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Pharma and Biotechnology": "https://img.freepik.com/free-photo/medium-shot-blurry-scientists-discussing_23-2148882134.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Project Management": "https://img.freepik.com/free-photo/people-working-as-team-company_23-2149136895.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Remote Sensing and GIS Services": `${process.env.REACT_APP_STP_URL}/geoinformaticsStaticData/MainBanners/1.jpg`,
    "Renewable Energy and Clean Technology": "https://img.freepik.com/free-photo/bulb-with-3d-windmill-project-saving-energy_23-2148907408.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
    "Social Incubation": "https://img.freepik.com/free-vector/flat-social-network-infography_1212-88.jpg?ga=GA1.1.1417762767.1732692123&semt=ais_hybrid",
};

const categoryCounts = {
    "Agri and Food Processing Technology": 15,
    "Cyber Security": 6,
    "Data Centers": 8,
    "Edtech": 5,
    "Electronics and Telecommunication": 11,
    "Health": 33,
    "IT and IT Enabled Services": 40,
    "Manufacturing": 15,
    "Mobile Computing": 6,
    "Open Source and Open Platform Technology": 11,
    "Pharma and Biotechnology": 17,
    "Project Management": 2,
    "Remote Sensing and GIS Services": 2,
    "Renewable Energy and Clean Technology": 63,
    "Social Incubation": 9,
};

export default function BusinessIncubationContent() {
    const [selectedTab, setSelectedTab] = useState("Agri and Food Processing Technology");

    return (
        <Grid container spacing={{ xs: 4, md: 8 }} justifyContent="center">
            <Grid item xs={12} lg={2.5}>
                <Card
                    className="custom-scrollbar"
                    sx={{
                        boxShadow: 3,
                        borderRadius: "10px",
                        width: { xs: "auto", lg: "250px" },
                        backgroundColor: "#fff",
                        height: "100%",
                        maxHeight: "360px",
                        overflowY: "auto",
                    }}
                >
                    {/* Title for the Scroller Box */}
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            color: "#1c2b78",
                            padding: "1rem 0.5rem",
                            borderBottom: "1px solid #ddd",
                            backgroundColor: "#f7f7f7",
                        }}
                    >
                        Our Focus Area
                    </Typography>

                    {/* List Items */}
                    <List>
                        {menuItems.map((item, idx) => (
                            <div key={idx}>
                                <ListItem disableGutters>
                                    <ListItemText>
                                        <Link
                                            color="inherit"
                                            underline="none"
                                            onClick={() => setSelectedTab(item)}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    my: -2.4,
                                                    backgroundColor:
                                                        selectedTab === item ? "#152265" : "transparent",
                                                    color: selectedTab === item ? "white" : "#999999",
                                                    padding: "1rem 1rem",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </Link>
                                    </ListItemText>
                                </ListItem>
                                {idx !== menuItems.length - 1 && <Divider />}
                            </div>
                        ))}
                    </List>
                </Card>

            </Grid>
            <Grid item xs={12} lg={6}>
                <Card
                    sx={{
                        maxWidth: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        boxShadow: 3,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                    // direction={{ xs: "column", md: "row" }}
                    // alignItems={{ xs: "center", md: "flex-start" }}
                    >
                        <Grid item xs={12} md={6}>
                            <Box sx={{ p: 3 }}>
                                <Typography
                                    variant="h6"
                                    sx={{ color: "#1c2b78", fontWeight: "bold" }}
                                >
                                    {selectedTab}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    textAlign={'left'}
                                    sx={{
                                        color: "#666666",
                                        fontSize: "0.89rem",
                                        fontWeight: "500",
                                        lineHeight: "1.3rem",
                                        letterSpacing: "1px",
                                        my: 2,
                                    }}
                                >
                                    {content[selectedTab]}
                                </Typography>
                                {/* <Link
                                    onClick={() => {
                                        const slug = slugs[selectedTab];
                                        if (slug) {
                                            window.open(`/growth-lab/${slug}`, "_self");
                                        }
                                    }}
                                    color="inherit"
                                    underline="none"
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{ color: "#0275d8", mt: 2, cursor: "pointer" }}
                                    >
                                        Read More →
                                    </Typography>
                                </Link> */}
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    py={1}
                                    sx={{
                                        position: 'relative',
                                        zIndex: 1,
                                    }}
                                >
                                    <Fab
                                        sx={{
                                            backgroundColor: '#152265',
                                            color: 'white',
                                            width: '100px',
                                            height: '75px',
                                            fontSize: '2rem',
                                            fontWeight: 'bold',
                                            borderRadius: '16px',
                                            zIndex: 2,
                                            '&:hover': { backgroundColor: '#1e3192' },
                                        }}
                                    >
                                        {categoryCounts[selectedTab]}
                                    </Fab>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "center" },
                                alignItems: { xs: "center", md: "center" },
                                p: 3,
                                mt: { xs: 0, md: 4 },
                            }}
                        >
                            <img
                                src={images[selectedTab]}
                                alt={selectedTab}
                                style={{
                                    width: "250px",
                                    height: "250px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    display: "block",
                                    margin: "auto",
                                }}
                            />
                        </Grid>

                    </Grid>
                </Card>
            </Grid >
        </Grid >
    );
}

