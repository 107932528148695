import React from 'react';
import { Box, Grid, Typography, Divider, List, ListItem } from '@mui/material';
import Image from "../../../Media/glbenefits.png";

export default function GrowthLabBenefits() {
    return (
        <Box sx={{ backgroundColor: '#d7e3fc', px: { xs: 3, sm: 6, md: 16 }, py: 4, pb: '3rem' }}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        sx={{
                            mt: 2,
                            color: "#364356",
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
                        }}
                    >
                        BENEFITS TO THE INCUBATEE AND CO-WORKING COMPANIES
                    </Typography>
                    <Divider
                        sx={{
                            width: '10%',
                            mx: 'auto',
                            mt: 2,
                            mb: 3,
                            borderBottomWidth: 1,
                            backgroundColor: '#364356',
                        }}
                    />
                </Grid>

                <Grid item xs={12} container spacing={4}>
                    <Grid item xs={12} sm={10} md={12} lg={6}>
                        <List sx={{ paddingLeft: { xs: '10px', sm: '20px' }, listStyleType: 'disc', textAlign: 'justify' }}>
                            {[
                                "Common reception, visitors lounge and hangout zones",
                                "Shared use of conference room /discussion rooms with services",
                                "Biometric access",
                                "Individual workstation with power back up and internet connection",
                                "Access to in – house library",
                                "Beverages from the vending machine in pantry",
                                "Free access to business training, information workshops, seminars, conferences, exhibitions, start-up award recommendations/applications",
                                "Mentoring and coaching from our Board of Mentors on a regular basis",
                                "Access to business services from our panel members at a nominal cost",
                                "Housekeeping support and parking"
                            ].map((item, index) => (
                                <ListItem key={index} sx={{ display: 'list-item', color: '#364356', fontSize: { xs: '1rem', sm: '1.2rem' } }}>
                                    {item}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${Image})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                minHeight: { xs: '300px', sm: '400px', md: '400px' },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}