import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import GeoinformaticsCard from "./GeoinformaticsCard";
import { staticData } from "./staticData";

const Content = () => {
    return (
        <Box sx={{ py: 6, backgroundColor: "#d7e3fc", color: "#1C1F35" }}>
            <Container >
                <Typography variant="h4" color='#152265' fontWeight="600" align="center" mb='3rem' gutterBottom>
                    Introduction
                </Typography>
                <Typography variant="body1" color="#364356" fontSize={'1.2rem'} paragraph textAlign={'justify'}>
                    The Geoinformatics Division of Science and Technology Park, Pune, stands as one of the leading geospatial institutions in India, recognized by the India Science, Technology and Innovation Portal (ISTI Portal), Government of India. We offer comprehensive geospatial mapping and software development solutions to government, semi-government, and private organizations. Backed by a team of skilled GIS professionals, our expertise extends from processing high-resolution satellite imagery to conducting advanced GIS-based spatial analyses.
                    <br />
                    <br />
                    Our services include GIS solutions for urban and rural planning, digitization of archival data, time-variant analysis using multi-temporal satellite images, risk-based GIS modeling, satellite image-based digital and visual classification, and accurate ground-level surveys. We actively embrace and promote open-source GIS technologies, showcased by the development of our proprietary Open PRP Suite, which further exemplifies our commitment to innovation and excellence in the field. Some of the core working area in the geospatial field involves:
                </Typography>

                {staticData?.length > 0 ? (
                    <Grid
                        container
                        mt="4rem"
                        justifyContent={"center"}
                        // alignItems={"center"}
                        sx={{
                            display: { xs: "grid", sm: "grid", md: "flex", lg: "flex" },
                            gap: { xs: "1rem", sm: "1rem", md: "2rem", lg: "3rem" },
                            px: { xs: "3rem", sm: "4rem", md: "4rem", lg: "2rem" },
                        }}
                    >
                        {staticData?.map((item) => (
                            <GeoinformaticsCard key={item?.Title} item={item} />
                        ))}
                    </Grid>
                ) : (
                    <Grid
                        style={{
                            display: "grid",
                            placeItems: "center",
                            py: 50
                        }}
                    >
                        <img
                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                            width={"80px"}
                            height={"80px"}
                            alt=""
                        />
                        <Typography >
                            No data available.
                        </Typography>
                    </Grid>
                )}
            </Container>
        </Box>
    );
};

export default Content;
